<template>
  <div class="section-content ma-0 pa-0">

    <v-divider></v-divider>

    <div class="mr-6">

      <div class="d-flex justify-center ma-0 pa-0 my-12" style="min-height:50px;" v-if="isLoading($options.name)">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>

      <v-expansion-panels v-model="panel" multiple v-else>
        <hb-expansion-panel expand-icon-off disabled>
            <template v-slot:title>
              Default Settings
            </template>
            <template v-slot:content>
              <hb-form label="Cleaning Deposit" required>
                <hb-text-field
                  v-model="auctions.cleaning_deposit"
                  v-validate="'required|max:45|integer|min_value:1'"
                  placeholder="Enter Cleaning Deposit"
                  id="cleaning_deposit"
                  name="cleaning_deposit"
                  data-vv-as="Cleaning Deposit"
                  :error="errors.has('cleaning_deposit')"
                >
                  <template v-slot:prepend-inner>
                    $
                  </template>
                </hb-text-field>
              </hb-form>

              <hb-form label="Clean Out Period (In Days)" required :divider="false">
                <hb-text-field
                  v-model="auctions.cleaning_period"
                  v-validate="'required|max:45|integer|min_value:1'"
                  placeholder="Enter Cleaning Period"
                  id="clean_out_period"
                  name="clean_out_period"
                  data-vv-as="Clean Out Period"
                  :error="errors.has('clean_out_period')"
                >
                </hb-text-field>
              </hb-form>

              <hb-bottom-action-bar @close="cancel" :cancel-off="isOPS">
                <template v-slot:right-actions>
                  <hb-btn color="primary" @click="save">Save</hb-btn>
                </template>
              </hb-bottom-action-bar>
            </template>
        </hb-expansion-panel>

      </v-expansion-panels>

    </div>
    
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Loader from "../../assets/CircleSpinner.vue";
import Status from "../../includes/Messages.vue";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
  name: "Auctions",
  mixins:[notificationMixin],
  // Added by BCT Team
   props: ['isOPS'],
  data() {
    return {
      auctions: {
        id: "",
        cleaning_deposit: "",
        cleaning_period: "",
      },
      panel: [0]
    };
  },
  components: { Loader, Status },
  mounted() {},
  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      // let res = await api.get(this, api.AUCTIONS);
      await api.get(this, api.AUCTIONS).then(res => {
        if (res.auctions === undefined) return;
        this.auctions = res.auctions;
      });
    },

    cancel() {
      this.fetchData();
    },

    async save() {
      this.validate(this).then( async (status) => {
        if(!status) {         
          this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.',list: this.errors.items });
          return;
        }
        if (this.auctions.id === "") {
          try{
            let data = this.auctions;
            await api.post(this, api.AUCTIONS, data).then(r => {
              this.fetchData();
            });
             // this.successSet(this.$options.name, "Auction Setting has been saved.");
             let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                let message = 'Auction Setting has been saved on ' + currentLocalDate;
                this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
                  
          }catch(error){
            return this.showMessageNotification({ id: this.$options.name, type: "error", description: error });
          }
         
        } else {
          try{
            let data = this.auctions;
            await api.put(this, api.AUCTIONS, data).then(r => {
              this.fetchData();
            }); 
            //this.successSet(this.$options.name, "Auction Setting has been updated.");
                        let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                let message = 'Auction Setting has been updated on ' + currentLocalDate;
                this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
          }catch(error){
            return this.showMessageNotification({ id: this.$options.name, type: "error", description: error });
          }
        }
       
      });
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.interaction-text {
  padding-bottom: 10px;
}
.interaction-text {
  padding: 16px 0 16px 24px;
  background-color: white;
}
.interaction-section {
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px rgba(11, 18, 29, 0.1),
    0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
}
.interaction-section-body {
  height: 0;
  overflow: hidden;
  background: white;
}
.interaction-section-body.open {
  height: auto;
  border-top: 2px solid #dce8ef;
}
.interaction-section h2 {
  font-size: 14px;
  line-height: 24px;
  color: #101318;
  background-color: #ffffff;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
}
</style>
